<template>
  <div class="home">
    <app-loading v-if="loading" />
    <h3 v-else-if="error">{{ error }}</h3>
    <div v-else>Redirecting...</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AppLoading from '@/components/AppLoading'

const { mapState } = createNamespacedHelpers('ccAuth')

export default {
  components: {
    AppLoading,
  },
  name: 'home',
  computed: {
    ...mapState({
      loading: state => state.initDataRequest.isLoading,
      error: state => state.initDataRequest.error,
    }),
  },
  created() {
    this.$store.dispatch('ccAuth/loadInitData').then(() => {
      if (this.$store.state.ccAuth.initDataRequest.error) {
        return
      }
      if (this.$store.state.ccAuth.cards) {
        this.$router.push({ name: 'select-card' }).catch(e => e)
      } else {
        this.$router.push({ name: 'create-card' })
      }
    })
  },
}
</script>
