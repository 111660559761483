<template>
  <div class="app">
    <app-nav title="Credit Card Authorization" />
    <main>
      <router-view />
    </main>
    <footer class="footer">
      <div class="footer__container">
        <center v-show="!showFooter">
          <!-- show when there's an error -->
          <p v-if="!companiesLoading && !initDataLoading">
            <a style="white-space: nowrap" :href="`tel:${contactInfo.phoneNumber}`"> {{ contactInfo.phoneNumber }}</a>
            <br />
            <a :href="`mailto:${contactInfo.email}`">{{ contactInfo.email }}</a>
          </p>
        </center>
        <div v-show="showFooter" class="inner">
          <p class="footer__text">
            Kasa uses industry leading security standards, including an SSL Certification and tokenization to ensure your Credit Card
            information is secure. This information is sent directly to our Credit Card processor, Stripe, and never stored on our servers.
          </p>
          <p>© {{ new Date().getFullYear() }} - All rights reserved</p>
        </div>
      </div>
    </footer>
    <PoweredByKasa :company-id="reservation.companyId" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppNav from '@/components/AppNav.vue'
import PoweredByKasa from '@/components/PoweredByKasa.vue'

const { mapState: mapContentState, mapGetters: mapContentGetters } = createNamespacedHelpers('content')
const { mapState: mapCCAuthState } = createNamespacedHelpers('ccAuth')

export default {
  components: {
    AppNav,
    PoweredByKasa,
  },
  computed: {
    ...mapContentGetters(['getCompanyById']),
    ...mapContentState({
      companiesLoading: state => state.companyData.isLoading,
    }),
    ...mapCCAuthState({
      initDataLoading: state => state.initDataRequest.isLoading,
      reservation: state => state.reservation,
    }),
    showFooter() {
      return this.$route.meta.showStripeBadge && !this.$store.state.ccAuth.setCardRequest.isLoading
    },
    contactInfo() {
      if (this.company) {
        return {
          phoneNumber: this.company.contactPhoneNumber,
          email: this.company.contactEmailAddress,
        }
      }
      return {
        phoneNumber: '+1 (650) 451 3444',
        email: 'reservations@kasa.com',
      }
    },
    company() {
      return this.getCompanyById(this.reservation.companyId)
    },
  },
}
</script>

<style media="screen" lang="scss">
@import '@/styles/global.scss';

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer__container {
  margin: 0 auto;
  margin-top: 2.5rem;
  padding-bottom: 1.25rem;
  font-size: 14px;
  text-align: left;
  color: $kasa-black-300;
  .inner {
    max-width: 40rem;
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 780px) {
  .footer__container .inner {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
