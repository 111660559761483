import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import ccAuth from './modules/ccAuth'
import content from './modules/content'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    ccAuth,
    content,
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ['auth', 'ccAuth.initDataRequest.isLoaded', 'ccAuth.cards', 'ccAuth.hasStripeAccount', 'ccAuth.guest', 'ccAuth.reservation'],
    }),
  ],
})
