<template>
  <div v-if="showFooter" class="powered-by-kasa">
    <div class="powered-by-kasa__label">Powered by <img src="../assets/logo.svg" alt="Kasa" class="powered-by-kasa__logo" /></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('content')

export default {
  name: 'PoweredByKasa',
  props: {
    companyId: String,
  },
  computed: {
    ...mapGetters(['getCompanyById']),
    showFooter() {
      const company = this.getCompanyById(this.companyId)
      return company && !company.isKasa
    },
  },
}
</script>

<style lang="scss" scoped>
.powered-by-kasa {
  width: 100%;
  margin-top: auto;

  &__label {
    width: 100%;
    padding: 0.825rem 1.5rem;
    background-color: $transparent-black-5;
    text-transform: uppercase;
    color: $kasa-black-300;
    font-size: 0.825rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
  }

  &__logo {
    height: 1.25rem;
    margin-left: 0.825rem;
  }
}
</style>
