import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter(to, from, next) {
        store.dispatch('content/loadCtfCompanyData')
        if (to.query.confirmationCode) {
          store.commit('ccAuth/SET_RESERVATION_CONFIRMATION_CODE', to.query.confirmationCode)
        }
        if (!confirmationCodeMatchesToken()) {
          console.error('Confirmation code in token does not match URL')
          console.log(`${store.state.ccAuth.reservation.confirmationCode} ${store.state.auth.confirmationCode}`)
          return next({ name: 'unauthorized' })
        }
        next()
      },
    },
    {
      path: '/select-card',
      name: 'select-card',
      component: () => import(/* webpackChunkName: "selectCard" */ './views/SelectCard.vue'),
      beforeEnter(to, from, next) {
        store.dispatch('content/loadCtfCompanyData')
        if (confirmationCodeMatchesToken() && Array.isArray(store.state.ccAuth.cards)) {
          if (store.state.ccAuth.cards.length > 0) {
            next()
          } else {
            next({ name: 'create-card' })
          }
        } else {
          next({ name: 'unauthorized' })
        }
      },
      meta: {
        showStripeBadge: true,
      },
    },
    {
      path: '/create-card',
      name: 'create-card',
      component: () => import(/* webpackChunkName: "createCard" */ './views/CreateCard.vue'),
      beforeEnter(to, from, next) {
        store.dispatch('content/loadCtfCompanyData')
        if (confirmationCodeMatchesToken() && store.state.ccAuth.initDataRequest.isLoaded) {
          next()
        } else {
          next({ name: 'unauthorized' })
        }
      },
      meta: {
        showStripeBadge: true,
      },
    },
    {
      path: '/success',
      name: 'success',
      component: () => import(/* webpackChunkName: "ccAuthSuccess" */ './views/CCAuthSuccess.vue'),
      beforeEnter(to, from, next) {
        store.dispatch('content/loadCtfCompanyData')
        if (store.state.ccAuth.reservation.selectedCardId) {
          next()
        } else {
          next({ name: 'unauthorized' })
        }
      },
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import(/* webpackChunkName: "unauthorized" */ './views/Unauthorized.vue'),
      beforeEnter(to, from, next) {
        store.dispatch('content/loadCtfCompanyData')
        next()
      },
    },
  ],
})

// the url param confirmation code must also be inside of the url param token
function confirmationCodeMatchesToken() {
  return store.state.ccAuth.reservation.confirmationCode === store.state.auth.confirmationCode
}

if (process.env.VUE_APP_AUTH_TOKEN_CHECK === 'true') {
  router.beforeEach((to, from, next) => {
    if (to.name === 'unauthorized') {
      console.log('redirected to unauthorized page')
      next()
    } else if (to.query.t) {
      console.log('setting auth token from query')
      store.commit('auth/SET_TOKEN', to.query.t)
      next()
    } else if (store.state.auth.token) {
      console.log('store already contains auth token')
      // It's a site reload
      store.commit('auth/SET_TOKEN', store.state.auth.token)
      next()
    } else {
      console.log('store does not contain auth token')
      next({ name: 'unauthorized' })
    }
  })
}

export default router
