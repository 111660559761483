<template>
  <div class="brand-logo">
    <Skeleton v-if="loading" class="brand-logo__loading" />
    <img v-else-if="company && !company.isKasa" :src="company.logoDark.url" :alt="company.displayName" class="brand-logo__img" />
    <img v-else src="../assets/logo.svg" alt="Kasa" class="brand-logo__img" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Skeleton from '@/components/Skeleton.vue'

const { mapGetters } = createNamespacedHelpers('content')

export default {
  name: 'BrandLogo',
  components: {
    Skeleton,
  },
  props: {
    loading: Boolean,
    companyId: String,
  },
  computed: {
    ...mapGetters(['getCompanyById']),
    company() {
      return this.getCompanyById(this.companyId)
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 4rem;

  &__loading {
    height: 1.5rem;
    width: 4rem;
  }

  &__img {
    height: 1.25rem;
    display: inline-block;
  }
}
</style>
