import { jwtDecode } from 'jwt-decode'
import { configureAPI } from 'kasa-api'

const state = {
  token: null,
  confirmationCode: null,
}

const mutations = {
  SET_TOKEN(state, token) {
    try {
      const { confirmationCode, auth0Token } = jwtDecode(token)
      state.token = token
      state.confirmationCode = confirmationCode
      configureAPI(auth0Token, token)
    } catch (error) {
      console.error('failed to identify user by decoding the jwt', error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
