import API from 'kasa-api'

const state = {
  initDataRequest: {
    isLoaded: false,
    isLoading: false,
    error: null,
  },
  setCardRequest: {
    isLoading: false,
    error: null,
  },
  createCardRequest: {
    isLoading: false,
    error: null,
  },
  guest: {
    _id: null,
    fullName: null,
    firstName: null,
    lastName: null,
    email: null,
  },
  hasStripeAccount: false,
  cards: null,
  reservation: {
    _id: null,
    confirmationCode: null,
    checkInDate: null,
    checkOutDate: null,
    selectedCardId: null,
    companyId: null,
  },
}

const getters = {
  selectedCardLast4Digits(state) {
    if (!state.reservation.selectedCardId || !state.cards) {
      return
    }
    const card = state.cards.find(card => card.cardId === state.reservation.selectedCardId)
    if (card) {
      return card.last4Digits
    }
  },
  guestName(state) {
    return state.guest.fullName || `${state.guest.firstName} ${state.guest.lastName}`
  },
  hasCards(state) {
    return Array.isArray(state.cards) && state.cards.length > 0
  },
}

const mutations = {
  SET_RESERVATION_CONFIRMATION_CODE(state, confirmationCode) {
    state.reservation.confirmationCode = confirmationCode
  },
  SET_REQUEST_STATE(state, { requestName, newState }) {
    state[requestName].isLoading = newState
  },
  SET_REQUEST_ERROR(state, { requestName, error }) {
    state[requestName].error = error
  },
  SET_INIT_DATA(state, initData) {
    state.guest = initData.guest
    state.reservation = initData.reservation
    state.hasStripeAccount = initData.hasStripeAccount
    state.cards = initData.cards
    state.initDataRequest.isLoaded = true
  },
  SET_SELECTED_CARD(state, cardId) {
    state.reservation.selectedCardId = cardId
  },
  ADD_CARD_TO_GUEST(state, cardData) {
    if (!state.cards) {
      state.cards = [cardData]
    } else {
      state.cards.push(cardData)
    }
  },
}

const actions = {
  async loadInitData({ state, commit }) {
    const requestName = 'initDataRequest'
    try {
      commit('SET_REQUEST_STATE', { requestName, newState: true })
      commit('SET_REQUEST_ERROR', { requestName, error: null })

      const { data } = await API.get(
        `/cc-auth/init?confirmationCode=${state.reservation.confirmationCode}&useForCriteria=incidental&useForCriteria=initialBooking`
      )

      commit('SET_INIT_DATA', data.data)
    } catch (error) {
      if (error.response.status === 401) {
        console.error(`Request to get init data unauthorized`, error)
        commit('SET_REQUEST_ERROR', {
          requestName,
          error: 'The link used has expired. Please contact us to request a new one.',
        })
      } else {
        console.error(`Failed to get init data`, error)
        commit('SET_REQUEST_ERROR', { requestName, error: `An error occurred while loading your data. Please reload the page!` })
      }
    } finally {
      commit('SET_REQUEST_STATE', { requestName, newState: false })
    }
  },

  async sendSelectedCreditCard({ state, commit }, creditCardId) {
    const requestName = 'setCardRequest'
    try {
      commit('SET_REQUEST_STATE', { requestName, newState: true })
      commit('SET_REQUEST_ERROR', { requestName, error: null })
      await API.post('/cc-auth/select-card', {
        confirmationCode: state.reservation.confirmationCode,
        cardId: creditCardId,
        useFor: ['initialBooking', 'incidental'],
      })
      commit('SET_SELECTED_CARD', creditCardId)
      return { success: true }
    } catch (error) {
      // TODO: Format errors
      commit('SET_REQUEST_ERROR', { requestName, error: error.message })
      return { success: false }
    } finally {
      commit('SET_REQUEST_STATE', { requestName, newState: false })
    }
  },

  async createCard({ state, commit }, token) {
    const requestName = 'createCardRequest'
    try {
      commit('SET_REQUEST_STATE', { requestName, newState: true })
      commit('SET_REQUEST_ERROR', { requestName, error: null })
      const { data } = await API.post('/cc-auth/create-card', {
        confirmationCode: state.reservation.confirmationCode,
        token,
        useFor: ['initialBooking', 'incidental'],
      })
      console.log(`${requestName} result`, JSON.stringify(data))
      if (data.success) {
        commit('ADD_CARD_TO_GUEST', data.data.card)
        commit('SET_SELECTED_CARD', data.data.card.cardId)
      }
      return data // { success: true, data: {...} }
    } catch (error) {
      // TODO: Format errors
      commit('SET_REQUEST_ERROR', { requestName, error: error.message })
      return { success: false, error }
    } finally {
      commit('SET_REQUEST_STATE', { requestName, newState: false })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
