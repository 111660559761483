import axios from 'axios'
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 15000,
})

export function configureAPI(auth0Token, userContextToken) {
  api.defaults.headers.common['Authorization'] = `Bearer ${auth0Token}`
  api.defaults.headers.common['User-Context'] = userContextToken
}

export default api
