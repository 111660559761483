<template>
  <component :is="tag" class="skeleton">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
}
</script>

<style lang="scss" scoped>
.skeleton {
  cursor: progress;
  border-radius: 8px;
  background-color: $neutral-grey-300;
  position: relative;
  overflow: hidden;
  display: flex;

  @media (prefers-reduced-motion: no-preference) {
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(100deg, transparent, rgba($kasa-black-300, 0.05), transparent);

      animation: loading 1s cubic-bezier(0.17, 0.67, 0.83, 0.67) infinite;
    }
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
</style>
