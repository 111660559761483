<template>
  <header class="header" role="banner">
    <div class="wrap">
      <div class="header__block">
        <BrandLogo :loading="companiesLoading || initDataLoading" :company-id="reservation.companyId" />
        <template v-if="title">
          <span class="header__sep"></span>
          <h1 class="header__title">{{ title }}</h1>
        </template>
      </div>
      <div v-if="subtitle" class="header__block">
        <h2 class="header__subtitle">{{ subtitle }}</h2>
      </div>
    </div>
  </header>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BrandLogo from '@/components/BrandLogo.vue'

const { mapState: mapContentState } = createNamespacedHelpers('content')
const { mapState: mapCCAuthState } = createNamespacedHelpers('ccAuth')

export default {
  components: { BrandLogo },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapContentState({
      companiesLoading: state => state.companyData.isLoading,
    }),
    ...mapCCAuthState({
      initDataLoading: state => state.initDataRequest.isLoading,
      reservation: state => state.reservation,
    }),
  },
  mounted() {},
  methods: {},
}
</script>

<style media="screen" lang="scss" scoped>
.header {
  border-bottom: 1px solid $border-color;
  background: $warm-neutral;
  padding: 1.5rem;
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width;
    margin: 0 auto;
    width: 100%;
  }
}
.header__block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__sep {
  display: inline-block;
  width: 1px;
  height: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background: $black;
}
.header__title {
  display: inline-block;
  font-size: 0.875rem;
  margin: 0;
  font-weight: 600;
}
.header__subtitle {
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
}
</style>
