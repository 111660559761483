export function convertImage(ctfImagesOrImage) {
  const image = Array.isArray(ctfImagesOrImage) ? ctfImagesOrImage[0] : ctfImagesOrImage

  return {
    bytes: image.bytes,
    height: image.height,
    width: image.width,
    publicId: image.public_id,
    url: image.secure_url,
    version: image.version.toString(),
    caption: image.context && image.context.custom.caption,
    alt: image.context && image.context.custom.alt,
  }
}

export function optionalEntry(entry) {
  // We're referring to optional fields, which need to be defined for vue.
  // Having undefined value would result in a Vue warning, as the referred field in the data does not exist.
  return entry || null
}
