import { createClient } from 'contentful'
import { convertImage, optionalEntry } from './contentful.helpers.js'

let ctfWebsiteClient

export function getCtfWebsiteClient() {
  if (ctfWebsiteClient) {
    return ctfWebsiteClient
  }

  ctfWebsiteClient = createClient({
    space: process.env.VUE_APP_CONTENTFUL_WEBSITE_SPACE_ID,
    accessToken: process.env.VUE_APP_CONTENTFUL_WEBSITE_ACCESS_TOKEN,
    environment: process.env.VUE_APP_CONTENTFUL_WEBSITE_ENVIRONMENT,
  })

  return ctfWebsiteClient
}

export async function getCtfCompanies() {
  try {
    const entries = await getCtfWebsiteClient().getEntries({
      content_type: 'company',
      include: 1,
    })

    return entries.items.map(entry => {
      return {
        companyId: entry.fields.companyId,
        displayName: entry.fields.displayName,
        isKasa: !!entry.fields.isKasa,
        logoLight: convertImage(entry.fields.logoLight),
        logoDark: convertImage(entry.fields.logoDark),
        contactPhoneLabel: entry.fields.contactPhoneLabel,
        contactPhoneNumber: entry.fields.contactPhoneNumber,
        contactEmailLabel: entry.fields.contactEmailLabel,
        contactEmailAddress: entry.fields.contactEmailAddress,
        gaaPageLinkLabel: optionalEntry(entry.fields.gaaPageLinkLabel),
        gaaPageLink: optionalEntry(entry.fields.gaaPageLink),
        kustomerBrandId: entry.fields.kustomerBrandId,
      }
    })
  } catch (e) {
    console.log('There was an error trying to load CTF companies: ', e)
    return []
  }
}
