import { getCtfCompanies } from '@/utils/contentful.api-client'
import { isExpired } from '@/utils/helpers'

const state = {
  companyData: {
    companies: [],
    fetchedAt: null,
    isLoading: false,
  },
}

const getters = {
  getCompanyById: state => companyId => state.companyData.companies.find(company => company.companyId === companyId),
}

const actions = {
  async loadCtfCompanyData({ state, commit }) {
    if (!state.companyData.fetchedAt || isExpired(state.companyData.fetchedAt)) {
      commit('SET_COMPANY_LOADING', true)
      const companies = await getCtfCompanies()
      commit('SET_COMPANIES', companies)
      commit('SET_COMPANY_LOADING', false)
      return companies
    }
    return state.companyData.companies
  },
}

const mutations = {
  SET_COMPANY_LOADING(state, isLoading) {
    state.companyData.isLoading = isLoading
  },
  SET_COMPANIES(state, companies) {
    state.companyData.companies = companies
    state.companyData.fetchedAt = Date.now()
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
